/* Hide timezone selector and duration of slot */
.DTP.qasa .DTP__details {
  display: none;
}

/* Add padding to the top of the wrapper */
.DTP.qasa .DTP__wrapper {
  padding-top: 24px;
}

/* --- CALENDAR GRID START --- */
.DTP.qasa .DTP__calendar-header {
  width: 80%;
  margin: 0 auto 8px;
}

.DTP.qasa .DTP__calendar-header-button {
  transform: scale(0.6);
  text-align: center;
}

.DTP.qasa .DTP__calendar-header-button:hover {
  border: none;
  box-shadow: none;
}

.DTP.qasa .DTP__calendar-header-button[disabled] {
  opacity: 0.5;
}

.DTP.qasa .DTP__calendar-header-button svg {
  display: inline-block;
}

.DTP.qasa .DTP__calendar-header--title {
  text-transform: capitalize;
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
  letter-spacing: -0.1px;
}

.DTP.qasa .DTP__calendar-grid--week-day {
  color: #78786c;
  font-size: 14px;
}

.DTP.qasa .DTP__calendar-grid--button {
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  border-radius: 100%;
  width: 46px;
}

.DTP.qasa .DTP__calendar-grid--button[disabled] {
  text-decoration: line-through;
  color: #a3a396;
}

.DTP.qasa .DTP__calendar-grid--available {
  background: none;
  border: none;
}

.DTP.qasa .DTP__calendar-grid--selected {
  background: #342620;
  color: #fff;
  box-shadow: none;
}

/* --- CALENDAR GRID END --- */

/* --- SLOT PICKER START --- */
.DTP.qasa .DTP__time-slots-list--header {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  margin-top: 10px;
}

.DTP.qasa .DTP__time-slots-list--header::first-letter {
  text-transform: uppercase;
}

.DTP.qasa .DTP__slot-list > * + * {
  margin-top: 8px;
}

.DTP.qasa .DTP__time-slot,
.DTP.qasa .DTP__time-slot:focus {
  box-shadow: inset 0 0 0 1px #e5e5df;
  border: none !important;
  border-radius: 16px;
  font-size: 16px;
  font-weight: 700;
}

.DTP.qasa .DTP__time-slot--selected,
.DTP.qasa .DTP__time-slot--selected:focus {
  background: #f9f9f6;
  box-shadow: inset 0 0 0 2px #342620;
}

.DTP.qasa .DTP__time-slot:hover {
  background: #f9f9f6;
  box-shadow: inset 0 0 0 1px #e5e5df;
}

.DTP.qasa .DTP__time-slot--selected:hover {
  box-shadow: inset 0 0 0 2px #342620;
}

/* --- SLOT PICKER END --- */

/* --- MOBILE/DESKTOP FIXES START --- */
.DTP.qasa .DTP__column--right {
  margin-top: 32px;
}

@media (min-width: 719px) and (max-width: 767px), (min-width: 800px) {
  .DTP.qasa .DTP__column--right {
    margin-top: 0;
  }

  .DTP.qasa .DTP__time-slots-group--wrapper {
    max-height: 408px;
    overflow-y: auto;
    padding-bottom: 24px;
  }

  .DTP.qasa .DTP__slot-list {
    padding-right: 8px;
  }

  .DTP.qasa .DTP__column--right,
  .DTP.qasa .DTP__column--left {
    padding-top: 32px;
  }

  .DTP.qasa .DTP__column--left {
    border-right: 1px solid #e5e5df;
    padding-left: 0;
    padding-right: 24px;
  }

  .DTP.qasa .DTP__column--right {
    padding-left: 40px;
    padding-right: 0;
  }

  .DTP.qasa .DTP__wrapper {
    padding-top: 0;
    margin-bottom: -32px;
    min-height: 496px;
  }
}
